<template>
    <DragList :items="items" @sort="$emit('sort', $event)" @change-position="$emit('change-position', $event)" #default="{item, idx}">
        <ProductScriptItem
            class="tw-mb-1"
            :item="item"
            :last="idx === lastIdx"
            :loading="loading"
            @sort="onSort"
            @edit="$emit('edit', $event)"
            @remove="$emit('remove', $event)"
            @change-position="$emit('change-position', $event)"
        />

    </DragList>
</template>
<script>
import DragList from "../partials/DragList";
const ProductScriptItem = () => import("./ProductScriptItem");
export default {
    name: 'ProductScriptList',
    components: {DragList, ProductScriptItem},
    props: {
        items: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        lastIdx() {
            return this.items.length - 1;
        }
    },
    methods: {
        onSort(sortedObject) {
            const list = [...this.items];
            const idx = list.findIndex(l => l.id === sortedObject.id);
            list[idx] = sortedObject;
            this.$emit('sort', list);
        },
    }
}
</script>
