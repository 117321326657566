<template>
    <div class="product-script-item" @click="closeAllMenus">
        <div class="product-script-item__parent" :class="{'product-script-item__parent--opened': opened}">
            <ScriptItem :menu-direction="last ? 'top' : 'bottom'"
                        :data="isChild ? parsedChildProduct : parsedProduct" @edit="$emit('edit', item.id)" @remove="$emit('remove', item.id)">
                <template #item.name>
                    <template v-if="loading">
                        <div class="skeleton" :style="{height: item.skeletonHeight || '20px'}"></div>
                    </template>
                    <template v-else>
                        <div v-if="isChild" class="product-script-item__subname">
                            <img src="@/assets/img/file-icon.svg" alt="file-icon"/>
                            {{ item.title }}
                        </div>
                        <div v-else class="product-script-item__name">
                            <img src="@/assets/img/folder-icon.svg" alt="folder-icon"/>
                            {{ item.title }}
                        </div>
                    </template>
                </template>
                <template v-if="!isChild" #additional-actions>
                    <div class="product-script-item__badge">{{ productsLength }}</div>
                    <AppButton class="product-script-item__more-button" :theme="opened ? 'primary': 'primary-outline'" square size="small" @click="opened = !opened">
                        <AppIcon icon="chevron-down"/>
                    </AppButton>
                </template>
            </ScriptItem>
        </div>
        <transition :css="false" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
            <ProductScriptList v-if="opened" class="product-script-item__children" :items="item.children"
                               @edit="$emit('edit', $event)" @remove="$emit('remove', $event)" @sort="onSort" @change-position="$emit('change-position', $event)"
                               :loading="loading"
            />
        </transition>
    </div>
</template>
<script>
import ScriptItem from "./ScriptItem";
import {CONTENT_ITEMS_SIZES} from "./ContentItems";
import AppButton from "../partials/AppButton";
import AppIcon from "../partials/AppIcon";
import ProductScriptList from "./ProductScriptList";
import {EventBus} from "@/helpers/eventBus";

export default {
    name: 'ProductScriptItem',
    components: {
        ProductScriptList,
        AppIcon,
        AppButton,
        ScriptItem,
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        last: Boolean,
        loading: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            opened: false,
        }
    },
    computed: {
        isChild() {
            return !(this.item.children && this.item.children.length);
        },
        productsLength() {
            return this.parsedProduct[2].value.split(', ').length;
        },
        parsedChildProduct() {
            return [
                {
                    name: 'common.table.number',
                    value: this.item.visibleIdx,
                    size: CONTENT_ITEMS_SIZES[0],
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                },
                {
                    name: 'common.table.name',
                    value: this.item.title,
                    size: CONTENT_ITEMS_SIZES[4],
                    key: 'name',
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                },
            ]
        },
        parsedProduct() {
            const getSubCategories = (products) => {
                return products.map(p => {
                    if(p.children && p.children.length) {
                        return p.title + ', ' + getSubCategories(p.children);
                    } else {
                        return p.title;
                    }
                }).join(', ');
            }
            const subCategories = this.item.children && this.item.children.length  ? getSubCategories(this.item.children) : '-';
            return [
                {
                    name: '№',
                    value: this.item.visibleIdx,
                    size: CONTENT_ITEMS_SIZES[0],
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                },
                {
                    name: 'Название',
                    value: this.item.title,
                    size: CONTENT_ITEMS_SIZES[2],
                    key: 'name',
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                },
                {
                    name: 'Подкатегории',
                    value: subCategories,
                    size: CONTENT_ITEMS_SIZES[3],
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                }
            ]
        },
    },
    methods: {
        closeAllMenus() {
          EventBus.$emit('close-all-menus');
        },
        onSort(sortedList) {
            const obj = {...this.item};
            obj.children = sortedList;
            this.$emit('sort', obj);
        },
        beforeEnter(el) {
            el.style.overflow = 'hidden';
            el.style.height = 0;
        },
        enter(el, done) {
            requestAnimationFrame(() => {
                el.style.height = el.scrollHeight + 'px';
                setTimeout(() => {
                    done();
                    el.style.overflow = '';
                    el.style.height = '';
                }, 250);
            });
        },
        beforeLeave(el) {
            el.style.overflow = 'hidden';
            el.style.height = el.scrollHeight + 'px';
        },
        leave(el, done) {
            requestAnimationFrame(() => {
                el.style.height = 0;
                setTimeout(() => {
                    done();
                }, 250);
            });
        },
    },
}
</script>
<style lang="scss">
@import '@/assets/scss/variables';
.product-script-item {
    $self: &;
    font-family: $font-secondary;
    background: #FFFFFF;
    border-radius: 12px;
    user-select: none;
    &__more-button {
        margin-left: 24px;
    }
    &__more-button svg {
        transition: transform .25s;
    }
    &__badge {
        background: #2552C6;
        opacity: 0.3;
        border-radius: 30px;
        padding: 3px 0 2px;
        width: 30px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #fff;
    }
    &__children {
        transition: height .25s;
        &:before {
            display: block;
            content: '';
            border-top: 1px solid #E1E1E1;
        }
        > * {
            margin-bottom: 0;
            &:after {
                content: '';
                display: block;
                height: 1px;
                background-color: #E1E1E1;
                margin-left: 32px;
                margin-right: 24px;
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
    &__parent {
        position: relative;
        cursor: grab;
        > * {
            width: 100%;
        }
        &--opened {
            #{$self}__more-button svg {
                transform: rotate(180deg);
            }
        }
    }
    &__name {
        display: flex;
        align-items: flex-start;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        text-transform: uppercase;
        img {
            width: 18px;
            height: 18px;
            margin-right: 6px;
        }
    }
    &__subname {
        display: flex;
        align-items: flex-start;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        img {
            width: 18px;
            height: 18px;
            margin-right: 4px;
        }
    }
}
.drag-source {
    > .product-script-item {
        position: relative;
        &:before {
            z-index: 1;
            pointer-events: none;
            content: '';
            position: absolute;
            display: block;
            background: #EDF2FF;
            border: 1px dashed #397AF5;
            border-radius: 12px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}
</style>
