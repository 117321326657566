<template>
    <div class="drag-item">
        <div class="drag-item__parent" ref="drag-target" data-drag-target @mousedown="onDragStart">
            <slot/>
        </div>
    </div>
</template>
<script>
export default {
    name: 'DragItem',
    components: {
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        itemValue: {
            type: String,
            default: 'id'
        },
        draggingId: Number,
    },
    computed: {
        itemId() {
            return this.item[this.itemValue];
        }
    },
    watch: {
        draggingId(value) {
            const dragTarget = this.$refs["drag-target"];
            if(dragTarget) {
                if(value) {
                    dragTarget.addEventListener('mouseenter', this.mouseOver);
                } else {
                    dragTarget.removeEventListener('mouseenter', this.mouseOver);
                }
            }
        }
    },
    methods: {
        mouseOver() {
            this.$emit('dover', this.itemId);
        },
        onDragStart(ev) {
            let target = ev.target.closest('[data-drag-target]');
            ev.preventDefault();
            ev.stopPropagation();
            let cloned = target.cloneNode(true);
            const parent = target.parentNode;
            const targetWidth = target.offsetWidth;
            let disabled = true;
            let {x: offsetX, y: offsetY} = parent.getBoundingClientRect();
            const onMove = e => {
                if(disabled) {
                    disabled = !(Math.abs(e.screenX - ev.screenX) > 5 || Math.abs(e.screenY - ev.screenY) > 5);
                    if(!disabled) {
                        // Первичная установка параметров. Этот код выполняется лишь раз
                        document.body.append(cloned);
                        cloned.style.pointerEvents = 'none';
                        cloned.style.width = targetWidth + 'px';
                        cloned.style.position = 'fixed';
                        cloned.style.top = '0';
                        cloned.style.left = '0';
                        cloned.style.zIndex = 100;
                        cloned.style.opacity = .6;
                        cloned.classList.add('drag-clone');
                        target.classList.add('drag-source');
                        offsetX = e.clientX - offsetX;
                        offsetY = e.clientY - offsetY;
                        document.documentElement.classList.add('grabbing');
                        this.$emit('dstart', this.item);
                        window.removeEventListener('mouseup', onMouseUp);
                        window.addEventListener('mouseup', onMouseUpLeave);
                    } else {
                        return;
                    }
                }
                const transform = `translate(${e.clientX - offsetX + 'px'}, ${e.clientY - offsetY + 'px'})`;
                cloned.style.webkitTransform = transform;
                cloned.style.mozTransform = transform;
                cloned.style.transform = transform;
            };
            const onMouseUpLeave = e => {
                window.removeEventListener('mousemove', onMove);
                window.removeEventListener('mouseup', onMouseUpLeave);
                parent.style.height = '';
                target.classList.remove('drag-source');
                document.documentElement.classList.remove('grabbing');
                cloned.remove();
                this.$emit('dend');
                e.stopPropagation();
                e.preventDefault();
            };
            const onMouseUp = e => {
                window.removeEventListener('mousemove', onMove);
                window.removeEventListener('mouseup', onMouseUp);
            };
            window.addEventListener('mousemove', onMove);
            window.addEventListener('mouseup', onMouseUp);
        },
    },
}
</script>
<style lang="scss">
.grabbing * {
    cursor: grabbing !important;
}
</style>
