<template>
    <transition-group name="product-script-list" class="drag-list" tag="div">
        <DragItem v-for="(item, idx) in items" :key="item[itemValue]" class="drag-list__item" :item="item" :item-value="itemValue"
                           :dragging-id="draggingId"
                           @dstart="onDragStart" @dover="onDragOver" @dend="onDragEnd">
            <slot :item="item" :idx="idx"/>
        </DragItem>
    </transition-group>
</template>
<script>
import DragItem from "./DragItem";
import {EventBus} from "@/helpers/eventBus";
export default {
    name: 'DragList',
    components: {DragItem},
    props: {
        items: {
            type: Array,
            required: true
        },
        itemValue: {
            type: String,
            default: 'id'
        }
    },
    data() {
        return {
            draggingId: null,
            updatedList: []
        }
    },
    methods: {
        onDragOver(id) {
            if(id !== this.draggingId) {
                let list = [...this.items];
                const overIdx = list.findIndex(l => l[this.itemValue] == id);
                const draggingIdx = list.findIndex(l => l[this.itemValue] == this.draggingId);
                if(draggingIdx > -1 && overIdx > -1) {
                    const dragging = list[draggingIdx];
                    const over = list[overIdx];
                    list[draggingIdx] = over;
                    list[overIdx] = dragging;
                    list.forEach((p, idx) => {
                        p.position = list.length - 1 - idx;
                    });
                    this.$emit('sort', list);
                    this.updatedList = list;
                }
            }
        },
        onDragStart(el) {
            this.draggingId = el[this.itemValue];
        },
        onDragEnd() {
            EventBus.$emit('close-all-menus');
            this.$emit('change-position', this.updatedList);
            this.$nextTick(() => {
                this.updatedList = [];
            })
            this.draggingId = null;
        },
    }
}
</script>
<style lang="scss">
.product-script-list {
    &-move {
        pointer-events: none;
        transition: transform .25s;
    }
}
</style>
