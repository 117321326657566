<template>
    <div class="app-admin-page application-page">
        <ApplicationHeader name="product.list" :count="count" add @click="onAdd"/>
        <!-- <PageLoader v-if="loading"/> -->
        <div>
            <ProductScriptList v-if="skeletonList.length" :loading="loading" :items="skeletonList" @remove="onRemove" @edit="onEdit" @sort="onSort" @change-position="delayedChangePosition"/>
            <div v-else class="empty-title">{{ $t("common.notFound") }}</div>
        </div>
    </div>
</template>

<script>
import ApplicationHeader from "../../components/admin/ApplicationHeader";
import ProductScriptItem from "../../components/admin/ProductScriptItem";
import AppIcon from "../../components/partials/AppIcon";
import AppButton from "../../components/partials/AppButton";
import PageLoader from "../../components/partials/PageLoader";
import {del, get, post} from "../../helpers/api";
import ProductScriptList from "../../components/admin/ProductScriptList";
import ListUtils from "../../mixins/ListUtils";
import ConfirmController from "@/services/ConfirmController";
import app from "@/main";

export default {
    name: "Product",
    mixins: [ListUtils],
    components: {
        ProductScriptList,
        PageLoader,
        AppButton,
        AppIcon,
        ApplicationHeader,
        ProductScriptItem
    },
    data() {
        return {
            list: [],
            count: 0,
            loading: false,
        }
    },
    created() {
        this.fetch();
    },
    methods: {
        onSort(sorted) {
            this.list = sorted;
        },
        delayedChangePosition(ev) {
            this.delay(() => this.onChangePosition(ev), 1000);
        },
        onChangePosition(changed) {
            if(changed.length){
                post('/products/update/positions', changed.map((p) => ({
                    position: p.position,
                    id: p.id,
                }))).then(() => {
                    this.recalculate();
                });
            }
        },
        recalculate() {
            let l = 0;
            const count = (products, idx) => {
                products.sort((b, a) => a.position - b.position);
                products.forEach((p, i) => {
                    l++;
                    p.visibleIdx = idx + (i + 1);
                    if(p.children && p.children.length) {
                        count(p.children, p.visibleIdx + '.');
                    }
                });
            }
            const data = JSON.parse(JSON.stringify(this.list));
            count(data, '');
            this.list = data;
            this.count = l;
        },
        fetch() {
            this.loading = true;
            return get('/products')
                .then(({data}) => {
                    this.list = data.data;
                    this.recalculate();
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onAdd(key) {
            if(key === 'add') {
                this.$router.push('/products/new');
            }
        },
        onEdit(id) {
            this.$router.push('/products/' + id);
        },
        onRemove(id) {
            const confirm = new ConfirmController()

            confirm.call().then(() => {
                del(`/products/${id}`).then(() => {
                    app.$store.commit("notifications/add", {
                        type: "success",
                        message: "common.successDelete"
                    })
                }).catch(() => {
                    app.$store.commit("notifications/error", "errors.failedWhenRemove")
                }).finally(() => {
                    this.fetch()
                })
            })
        }
    },

    computed: {
        skeletonList() {
            const list = []
            for (let index = 0; index < 10; index++) {
                list.push({
                    id: index,
                    skeleton: true,
                })
            }
            if (this.loading) {
                return list
            }
            return this.list
        }
    }
}
</script>
