<template>
    <div class="d-flex justify-center pt-100 pb-100">
        <Loader class="mx-auto"/>
    </div>
</template>
<script>
import Loader from "../Loader";
export default {
    name: 'PageLoader',
    components: {Loader}
}
</script>
